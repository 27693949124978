import { render, staticRenderFns } from "./DTRCSformat.vue?vue&type=template&id=27e808a4&scoped=true&"
import script from "./DTRCSformat.vue?vue&type=script&lang=js&"
export * from "./DTRCSformat.vue?vue&type=script&lang=js&"
import style0 from "./DTRCSformat.vue?vue&type=style&index=0&id=27e808a4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27e808a4",
  null
  
)

export default component.exports